import Vue from 'vue';
import VueRouter from 'vue-router';
import TodoDetail from '@/views/todo-detail/index.vue';
import Testing from '@/views/Testing.vue';
import Todo from '@/views/todo-tmp.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/todo',
  },
  {
    path: '/todo',
    name: '待办',
    meta: {
      title: '我的待办',
    },
    component: Todo,
  },
  {
    path: '/todo-detail',
    name: '待办详情',
    component: TodoDetail,
    beforeEnter(to, from, next) {
      document.title = to?.query?.isHistory ? '已办详情 - 腾讯工单' : '待办详情 - 腾讯工单';
      next();
    },
  },
  {
    path: '/test-page',
    name: '测试页面',
    component: Testing,
  },

  {
    path: '/move-check',
    name: '搬迁-主管验收确认',
    component: () => import('@/views/todo-details-paeg/Move/move-check.vue'),
  },
  {
    path: '/move-outin',
    name: '搬迁-出入库',
    component: () => import('@/views/todo-details-paeg/Move/move-outin.vue'),
  },
  {
    path: '/move-with',
    name: '搬迁-上下架随工',
    component: () => import('@/views/todo-details-paeg/Move/move-with.vue'),
  },
  {
    path: '/move-updown',
    name: '搬迁-上下架',
    component: () => import('@/views/todo-details-paeg/Move/move-updown.vue'),
  },
  {
    path: '/common',
    name: '待办-通用工单',
    component: () => import('@/views/todo-details-paeg/Common/common.vue'),
  },
  {
    path: '/asset_out',
    name: '待办-备件出库',
    component: () => import('@/views/todo-details-paeg/Asset/asset_out.vue'),
  },

  {
    path: '/finished',
    name: '已完成',
    component: () => import('@/views/done.vue'),
    meta: {
      title: '我的已办',
    },
  },

  {
    path: '/signature',
    name: '电子签名',
    component: () => import('@/components/signature.vue'),
  },

  {
    path: '/fins-move-check',
    name: '已完成-搬迁-主管验收确认',
    component: () => import('@/views/finished-details-page/fin-Move/fins-move-check.vue'),
  },
  {
    path: '/fins-move-outin',
    name: '已完成-搬迁-出入库',
    component: () => import('@/views/finished-details-page/fin-Move/fins-move-outin.vue'),
  },
  {
    path: '/fins-move-with',
    name: '已完成-搬迁-上下架随工',
    component: () => import('@/views/finished-details-page/fin-Move/fins-move-with.vue'),
  },
  {
    path: '/fins-move-updown',
    name: '已完成-搬迁-上下架',
    component: () => import('@/views/finished-details-page/fin-Move/fins-move-updown.vue'),
  },
  {
    path: '/fins-common',
    name: '',
    component: () => import('@/views/finished-details-page/fin-common/fins-common.vue'),
  },
  {
    path: '/fins-asset_out',
    name: '已办-备件出库',
    component: () => import('@/views/finished-details-page/fin-Asset/fins-asset_out.vue'),
  },

  // 退役路由   暂无已完成页面
  {
    path: '/retire-block',
    name: '退役拔线/封箱',
    component: () => import('@/views/todo-details-paeg/Retire/retire-block.vue'),
  },
  {
    path: '/retire-check',
    name: '退役随工检查',
    component: () => import('@/views/todo-details-paeg/Retire/retire-check.vue'),
  },
  {
    path: '/retire-ssd',
    name: '退役拔盘',
    component: () => import('@/views/todo-details-paeg/Retire/retire-ssd.vue'),
  },
  {
    path: '/retire-enter',
    name: '退役入库',
    component: () => import('@/views/todo-details-paeg/Retire/retire-enter.vue'),
  },
  {
    path: '/upload',
    name: '文件上传',
    component: () => import('@/components/uploadNew.vue'),
  },
  {
    path: '/camera',
    name: '企业微信扫一扫',
    component: () => import('@/components/camera.vue'),
  },
  {
    path: '/location',
    name: '企业微信定位',
    component: () => import('@/components/Formily/Contact.vue'),
  },
  {
    path: '/handle',
    name: '企业微信定位',
    component: () => import('@/views/Handle.vue'),
  },
  {
    path: '/retireShowTest',
    name: '退役文字展示',
    component: () => import('@/components/commonShowData/retireShowTest.vue'),
  },
  {
    path: '/loading/:appid?',
    name: 'loading',
    component: () => import('@/views/Loading.vue'),
  },
  {
    // 自动化变更进展页处理跳转至tencent域名下
    path: '/autoManage/workbench/execute/progress',
    name: 'autoExecuteProgress',
    component: () => import('@/views/auto/execute-progress.vue'),
  },
  {
    path: '/CommonModule',
    name: '通用工单',
    component: () => import('@/components/CommonModule.vue'),
  },
  {
    path: '/testPage',
    name: 'test',
    component: () => import('@/components/testPage.vue'),
  },
  {
    path: '/overview',
    name: '总览页',
    component: () => import('@/views/overView/overview.vue'),
  },

  {
    path: '/retirePN',
    name: 'PN搜索',
    component: () => import('@/components/retirePN.vue'),
  },
  {
    path: '/search',
    name: '模糊查询',
    component: () => import('@/components/search.vue'),
  },
  // 机房裁撤
  {
    path: '/dissolve-evaluated',
    name: '机架裁撤评估单',
    component: () => import('@/views/todo-details-paeg/dissolve/dissolve-evaluated.vue'),
  },
  {
    path: '/dissolve-cut-cable',
    name: '机架裁撤拆线操作单',
    component: () => import('@/views/todo-details-paeg/dissolve/dissolve-cut-cable'),
  },
  // 维修部件
  {
    path: '/repair-components',
    name: '维修部件工单查询',
    component: () =>
      import('@/views/todo-details-paeg/repair-components/Queryassetwork/repair-components'),
  },
  {
    path: '/repair-components-detail',
    name: '维修部件工单详情',
    component: () =>
      import('@/views/todo-details-paeg/repair-components/Queryassetwork/repair-components-detail'),
  },
  // 现场派单服务台
  {
    path: '/serviceCounter',
    name: '现场派单服务台',
    meta: {
      title: '现场派单服务台',
    },
    component: () => import('@/views/todo-details-paeg/serviceCounter/home'),
  },
  {
    path: '/technicalSupport',
    name: '服务器技术申请',
    component: () => import('@/views/todo-details-paeg/serviceCounter/components/technicalSupport'),
  },
  {
    path: '/haltrequest',
    name: '服务器授权停机申请',
    component: () => import('@/views/todo-details-paeg/serviceCounter/components/haltrequest'),
  },
  {
    path: '/commonConsult',
    name: '通用查询',
    component: () => import('@/views/todo-details-paeg/serviceCounter/components/commonConsult'),
  },
  {
    path: '/queryDealer',
    name: '处理人查询',
    meta: {
      title: '处理人查询',
    },
    component: () => import('@/views/todo-details-paeg/serviceCounter/components/queryDealer'),
  },
  {
    path: '/queryAsset',
    name: '网络工单查询',
    meta: {
      title: '网络工单查询',
    },
    component: () => import('@/views/todo-details-paeg/serviceCounter/components/queryAsset'),
  },
  {
    path: '/osVersionDownload',
    name: '网络OS版本下载',
    meta: {
      title: '网络OS版本下载',
    },
    component: () =>
      import('@/views/todo-details-paeg/serviceCounter/components/osVersionDownload'),
  },

  // 设备管理工具
  {
    path: '/managementTool',
    name: '设备管理工具',
    meta: {
      title: '设备管理工具',
    },
    component: () => import('@/views/todo-details-paeg/equipmentManagement/home'),
  },
  {
    path: '/deviceDetails',
    name: '设备详情信息',
    meta: {
      title: '设备详情信息',
      DeviceCategoryID: '',
    },
    component: () =>
      import('@/views/todo-details-paeg/equipmentManagement/components/deviceDetails'),
  },
  {
    path: '/otherTools',
    name: '其他工具',
    meta: {
      title: '其他工具',
    },
    component: () => import('@/views/todo-details-paeg/equipmentManagement/components/otherTools'),
  },
  {
    path: '/numberDetails',
    name: '通过编号查看设备详情',
    meta: {
      title: '通过编号查看设备详情',
    },
    component: () =>
      import('@/views/todo-details-paeg/equipmentManagement/components/numberDetails'),
  },
  {
    path: '/labelVerification',
    name: '标牌粘贴拍照核验',
    meta: {
      title: '标牌粘贴拍照核验',
    },
    component: () =>
      import('@/views/todo-details-paeg/equipmentManagement/components/labelVerification'),
  },
  {
    path: '/successfulVerification',
    name: '核验成功',
    meta: {
      title: '核验成功',
    },
    component: () =>
      import('@/views/todo-details-paeg/equipmentManagement/components/successfulVerification'),
  },
  {
    path: '/measurePointTestMobile',
    name: '测试任务(移动端)',
    meta: { title: '测试任务(移动端)' },
    component: () =>
      import('@/views/todo-details-paeg/verificationTestManage/measurePointTestMobile/index'),
  },
  //
  {
    path: '/testDetails',
    name: 'testDetails',
    meta: { title: '测试详情', icon: 'el-icon-set-up' },
    component: () =>
      import('@/views/todo-details-paeg/verificationTestManage/measurePointTestMobile/testDetails'),
  },
  // {
  //   path: '/testDetails2', name: 'testDetails2', meta: { title: '测试详情', icon: 'el-icon-set-up' },
  //   component: () => import('@/views/todo-details-paeg/verificationTestManage/measurePointTestMobile/testDetails2'),
  // },
  // {
  //   path: '/l6_details', name: 'l6_details', meta: { title: '测试详情', icon: 'el-icon-set-up' },
  //   component: () => import('@/views/todo-details-paeg/verificationTestManage/measurePointTestMobile/l6_details'),
  // },
  //
  {
    path: '/operationsTools',
    name: '诊断工具',
    component: () => import('@/views/itOperation/tools'),
  },
  {
    path: '/related-wework',
    name: '关联企业微信',
    meta: {
      title: '关联Dcosp工单',
    },
    component: () => import('@/views/related-wework/related_ot.vue'),
  },
  // 建设平台
  {
    path: '/constructionPlatform',
    name: '建设平台',
    component: () => import('@/views/todo-details-paeg/constructionPlatform/home/home'),
  },
  {
    path: '/constructionProblem',
    name: '建设问题',
    component: () =>
      import('@/views/todo-details-paeg/constructionPlatform/constructionProblem/home'),
  },
  {
    path: '/testProblem',
    name: '测试问题',
    component: () => import('@/views/todo-details-paeg/constructionPlatform/testProblem/home'),
  },
  {
    path: '/operationMaintenanceProblem',
    name: '运维问题',
    component: () =>
      import('@/views/todo-details-paeg/constructionPlatform/operationMaintenanceProblem/home'),
  },
];
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
