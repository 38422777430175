import axios from "axios";
import { Toast } from "vant";
const service = axios.create({
  // 请求根地址
  baseURL: '/',
  timeout: "50000",
});

// 请求拦截
service.interceptors.request.use(
  // 在发送请求之前做些什么
  (config) => {
    // 设置请求头
      const token = sessionStorage.getItem("AuthToken");
      if (token) {
        config.headers['authToken'] = sessionStorage.getItem("AuthToken"); // 设置请求头
      } else {
        config.headers['authToken'] = '///';
      }
      config.headers['userid'] = sessionStorage.getItem('userid') || '',

      config.headers['Content-Type'] = "application/json;charset=utf-8";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截
service.interceptors.response.use(
  (response) => {
    // console.log(response,"response请求");

    // authToken会过期 1小时，判断当前请求的status为401就需要重新获取token
    const status = response?.data?.status;
    const msg = response?.data?.msg;
    
    if (status == 401) {
      Toast.setDefaultOptions({ duration: 1500 });
      Toast.fail(response.data.msg + ', 正在重新登录');
      const AGENT_ID = process.env.NODE_ENV === 'development' ? '1000036' : '1000035';
      const HOST = process.env.NODE_ENV === 'development' ? 'https://test.otob.dcops.qq.com' : 'https://otob.dcops.qq.com';
      location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.getConfigItem('corpID')}&redirect_uri=${HOST}/loading&response_type=code&scope=snsapi_base&state=STATE&agentid=${AGENT_ID}#wechat_redirect`
    }
    if (msg && status !== 200) {
      response.data.msg = msg + '\n请联系开发人员处理';
    } else if (response.data && status !== 200) {
      response.data = {
        ...(response.data || {}),
        msg: '请联系开发人员处理。'
      }
    } else {
      Toast.fail('\n请稍等60秒后再试。如果错误重复出现，请联系philyuliu(刘宇)处理。');
    }
    // return response.data.Data;  
    return response;
  },
  (error) => {
    // if (error.code == 'ECONNABORTED') {
      Toast.setDefaultOptions({ duration: 2000 });
      Toast.fail(error.message + '\n请稍等60秒后再试。如果错误重复出现，请联系philyuliu(刘宇)处理。');
      error.message = error.message + '\n请稍等60秒后再试。如果错误重复出现，请联系philyuliu(刘宇)处理。';
    // }
    return Promise.reject(error);
  }
);

export default service;

// import axios from "axios";

// const ins = axios.create({
//   baseURL: "/api",
//   timeout: 6000,
// });

// // 请求拦截
// ins.interceptors.request.use(
//   (config) => {
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// // 响应拦截
// ins.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// const request = async (config) => {

//   const { url = "", data = {}, method = "get" } = config;

//   const requestIns = () => {
//     switch (method.toLocaleUpperCase()) {
//       case "GET":
//         return ins.get(url, { params: data });
//       case "POST":
//         return ins.post(url, data);
//       case "PUT":
//         return ins.put(url, data);
//       case "DELETE":
//         return ins.delete(url, { data });
//       default:
//         return ins.get(url, { params: data });
//     }
//   };

//   try {
//     const { data } = await requestIns();
//     if (data.code == !200) {
//       throw Error(data.message);
//     }
    
//     return data || [];
    
//   } catch (error) {
//     // const errorMsg =
//     //   error?.message?.replace(/(.+)with status code [0-9]+$/, "$1") || "";
//       Toast.fail(error)
//       // Notify({ type: 'warning', message: errorMsg });
//     // return {
//     //     error,
//     //     requestError: true,
//     // };
//     return false;
//   }
// };

// export default request;
