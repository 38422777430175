<template>
  <div v-loading="loading" class="wrapper">
    <div class="todo-box">
      <div class="content" :style="{ height: height + 'px' }">
        <div class="nav" :class="[{ 'is-fold': isFold }]">
          <van-sidebar class="sidebar" v-model="sidebarNum" @change="getWhereType">
            <van-sidebar-item
              :class="[{ 'is-fold': isFold }, 'navbar']"
              v-for="item in titleLists"
              :key="item.name"
              :title="item.name"
              :badge="item.count"
            />
          </van-sidebar>
        </div>
        <div :class="['list-box', { 'is-stretch': isFold }]">
          <div :class="['flex-vc', 'todo-title', { 'is-stretch': isFold }]">
            <div @click="isFold = !isFold" class="ml5 icon-left">
              <el-icon class="f20" name="s-unfold" v-if="isFold" />
              <el-icon class="f20" name="s-fold" v-else />
            </div>
            <h4 style="text-align: center; font-weight: 500" class="w m10 f16">
              待办列表({{ total || 0 }})
            </h4>
            <div class="mr5 icon-left" style="display: flex">
              <van-icon name="search" v-if="hasSearch" class="f20" @click="hasSearch = false" />
              <van-icon name="arrow-up" v-else class="f20" @click="onCancel" />
              <!-- <el-icon class="f20" name="s-search" v-if="hasSearch" /> -->
            </div>
          </div>
          <transition name="fade" appear>
            <div
              key="search"
              v-show="!hasSearch"
              :class="[hasSearch ? 'hide-search' : 'show-search', { 'is-stretch': isFold }]"
            >
              <!-- <van-search
                v-model="searchStr"
                show-action
                placeholder="请输入搜索关键词"
                @search="onSearch"
                @cancel="onCancel"
                :clearable="false"
                @clear="onSearch('')"
              ></van-search> -->
              <scanner @enter="checkSearch" @cancel="onCancel" :searchValue="searchValue"></scanner>
            </div>
          </transition>

          <van-pull-refresh v-model="refreshing" @refresh="onRefresh" class="todo-list">
            <van-list
              v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="onLoad"
              offset="10"
              :class="hasSearch ? 'hide-search-list' : 'show-search-list'"
            >
              <van-collapse v-model="activeName">
                <div v-for="item in ticketList" :key="item.TaskId || item.TaskOrderId">
                  <van-collapse-item :name="item.TaskId || item.TaskOrderId">
                    <template #title>
                      <div>
                        <van-tag
                          v-if="URGENT_TYPE[item.IsUrgent]"
                          effect="dark"
                          class="mr5"
                          size="small"
                          :type="URGENT_TYPE[item.IsUrgent].color"
                          disable-transitions
                        >
                          {{ URGENT_TYPE[item.IsUrgent].text || '紧急' }} </van-tag
                        ><span
                          :class="
                            URGENT_TYPE[item.IsUrgent]
                              ? 'color-' + URGENT_TYPE[item.IsUrgent].color
                              : ''
                          "
                          >{{
                            `[${item.TypeName || item.Type || '暂无分类'}]${
                              item.TodoName || item.TitleContent
                            }`
                          }}</span
                        >
                      </div>
                    </template>
                    <div class="details-box">
                      <table class="w">
                        <tr v-for="item in item.TodoDesc" :key="item.key">
                          <td class="f700 text-r">
                            {{ item.key }}
                          </td>
                          <td class="pl5 pr5" style="white-space: pre-line" v-html="item.value" />
                        </tr>
                      </table>
                      <div class="foot">
                        <div class="foot-item">
                          <van-button
                            size="small"
                            style="background-color: #327aee; color: #ffffff"
                            @click="gotoDetail(item)"
                          >
                            去处理
                          </van-button>
                        </div>
                      </div>
                    </div>
                  </van-collapse-item>
                </div>
              </van-collapse>
            </van-list>
          </van-pull-refresh>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getOtobTicket, getAllType, getOverAllType, getOverTicket } from '@/api/tickets/ticketsApi';
import { mapGetters } from 'vuex';
import { Notify } from 'vant';
import Scanner from './Scanner.vue';
import '@/assets/iconfont/iconfont.css';
import router from '../router/router';
export default {
  components: {
    Scanner,
  },
  computed: {
    ...mapGetters(['getConfigItem']),
  },
  data() {
    return {
      overView: {
        index: '',
        key: '',
      },
      URGENT_TYPE: {
        1: {
          text: '紧急',
          color: 'danger',
        },
        2: {
          text: '加急',
          color: 'warning',
        },
      },
      // 工单类型判断条件
      height: window.innerHeight,
      Type: '',
      TaskTypeId: '',
      TotalRows: '',
      //分页列表渲染
      List: [],
      ticketList: [],
      searchStr: this.$route.query?.search,
      total: 0,
      // 分页展示数据
      dataValueList: [],
      isFold: false,
      isLoading: false,
      activeName: [],
      sidebarNum: parseInt(this.$route.query.num) || 0,
      loading: false,
      finished: true,
      hasSearch: !this.$route.query?.search,
      refreshing: false,
      // 数据类型展示
      titleLists: [],
      // 总览跳转数据类型
      overViewTitLists: [],
      //userId
      UserID: sessionStorage.getItem('userid'),
      searchValue: '',
    };
  },
  async created() {
    console.log(this.$route.query, 'this.$route.query');
    window.addEventListener('resize', () => {
      // We execute the same script as before
      this.height = window.innerHeight;
    });
    await this.getTaskType();
    if (this.$route.query.type) {
      await this.getOverviewType();
    } else {
      if (sessionStorage.getItem('key')) {
        // await this.getTicketByType(this.sidebarNum || 0, this.searchStr);

        this.searchStr = sessionStorage.getItem('key');
        this.searchValue = sessionStorage.getItem('key');
        this.onSearch(this.searchStr);
      } else {
        await this.getTicketByType(this.sidebarNum || 0, this.searchStr);
      }
    }
  },
  mounted() {},
  methods: {
    checkSearch(val) {
      const isKeywordValid = val && val.length >= 2;
      if (isKeywordValid || !val) {
        this.onSearch(val);
      } else {
        Notify({ type: 'warning', message: '使用关键字搜索，请输入至少2个字符' });
      }

    },

    async onSearch(val) {
      this.searchStr = val;
      sessionStorage.setItem('key', val);
      try {
        this.loading = true;
        const { List, TotalRows } = await getOtobTicket({
          Type: this.Type,
          KeyWords: val,
        });
        this.$router.replace({ path: '/todo', query: { num: this.sidebarNum || 0, search: val } });
        this.ticketList = List.map((item) => ({
          ...item,
          TodoDesc: this.parseJson(item.TodoDesc || '[]'),
        }));
        this.activeName = this.ticketList.map((item) => item?.TaskId || item?.TaskOrderId);
      } catch (e) {
        console.log(e);
        this.$toast(e);
      } finally {
        this.loading = false;
      }
    },
    onCancel() {
      this.searchValue = '';
      this.hasSearch = true;
      this.onSearch('');
    },
    //左侧数量类型统计
    async getOverviewType() {
      try {
        console.log(sessionStorage.getItem('titleLists'), "sessionStorage.getItem('titleLists')")
        if (sessionStorage.getItem('titleLists')) {
          const titleLists = JSON.parse(sessionStorage.getItem('titleLists'));
          if (Array.isArray(titleLists)) {
            this.titleLists = titleLists;
            return;
          } else {
            this.titleLists = [];
          }
        }
      } catch (e) {}
      try {
        const res = await getOverAllType();
        this.titleLists = res.map((item) => ({
          name: item.TypeName || item.Type,
          value: item.Type,
          count: item.amount,
        }));
        this.titleLists.unshift({
          name: '全部待办',
          value: '',
          count: this.titleLists.reduce((res, item) => parseInt(item.count) + res, 0),
        });
        sessionStorage.setItem('titleLists', JSON.stringify(this.titleLists));
        this.getGoindex(this.$route.query.type);
      } catch (e) {
        console.log(e);
      }
    },
    //总览页面分页查询
    async getOverTicket(i) {
      console.log('6 正常触发getOverTicket');
      console.log(i, 'over');
      const { value } = this.titleLists[i || 0];
      this.$router.replace({ path: '/todo', query: { num: i } });
      this.Type = value;
      this.loading = true;
      try {
        const { List, TotalRows } = await getOverTicket({
          Type: value,
        });
        this.ticketList = List.map((item) => ({
          ...item,
          TodoDesc: this.parseJson(item.TodoDesc || '[]'),
        }));
        this.activeName = this.ticketList.map((item) => item?.TaskId || item?.TaskOrderId);
        this.total = TotalRows;
        this.titleLists[i || 0].count = TotalRows;
      } catch (e) {
        this.$toast(e);
      } finally {
        this.loading = false;
      }
    },
    //当前id下的某个工单的下标
    getGoindex(type) {
      console.log('3 进入getGoindex');
      this.overView.key = this.$route.query.key;
      let index = this.titleLists.findIndex((item) => item.value == type);
      this.overView.index = index;
      this.sidebarNum = index;
      if (index) {
        this.getWhereType();
        return;
      }
      if (index === -1) {
        // this.$toast(`当前账号没有(${type})工单类型，请返回上级页面`)
        Notify(`当前账号没有(${type})类型工单，请返回上级页面`);
        return;
      }
    },
    //判断是走总览的方法还是todo的方法
    getWhereType() {
      console.log('4 getWhereType');
      const key = this.overView.key;
      if (key === 'overview') {
        console.log('5 getOverTicket');
        this.getOverTicket(this.sidebarNum || 0);
        return;
      } else {
        this.getTicketByType(this.sidebarNum || 0);
      }
    },
    // 获取数据类型
    async getTaskType() {
      try {
        console.log(sessionStorage.getItem('titleLists'), "sessionStorage.getItem('titleLists')")
        if (sessionStorage.getItem('titleLists')) {
          const titleLists = JSON.parse(sessionStorage.getItem('titleLists'));
          if (Array.isArray(titleLists)) {
            this.titleLists = titleLists;
            return;
          } else {
            this.titleLists = [];
          }
        }
      } catch (e) {}
      try {
        const res = await getAllType();
        this.titleLists = res.map((item) => ({
          name: item.TypeName || item.Type,
          value: item.Type,
          count: item.amount,
        }));
        this.titleLists.unshift({
          name: '全部待办',
          value: '',
          count: this.titleLists.reduce((res, item) => parseInt(item.count) + res, 0),
        });
        sessionStorage.setItem('titleLists', JSON.stringify(this.titleLists));
      } catch (e) {
        console.log(e);
      }
    },

    //点击品类进行过滤接口
    async getTicketByType(i, searchStr) {
      const { value } = this.titleLists[i || 0];
      this.$router.replace({ path: '/todo', query: { num: i, key: searchStr } });
      this.Type = value;
      this.loading = true;
      this.searchStr = searchStr;
      try {
        const { List, TotalRows } = await getOtobTicket({
          Type: value,
        });
        this.ticketList = List.map((item) => ({
          ...item,
          TodoDesc: this.parseJson(item.TodoDesc || '[]'),
        }));
        this.activeName = this.ticketList.map((item) => item?.TaskId || item?.TaskOrderId);
        console.log(this.ticketList);
        this.total = TotalRows;
        this.titleLists[i || 0].count = TotalRows;
      } catch (e) {
        this.$toast(e);
      } finally {
        this.loading = false;
      }
    },

    parseJson(json) {
      try {
        return JSON.parse(json);
      } catch (e) {
        return [];
      }
    },

    // 工单详情页
    gotoDetail(item) {
      // 单独取出工单类型
      this.loading = true;
      // let url = '';
      if (!item.TaskId) {
        console.log('走1');
        this.$router.push({
          path: '/' + item.Type,
          query: { Id: item.TaskOrderId },
        });
      } else {
        console.log(this.searchStr, '走2');
        this.$router.push({
          path: '/todo-detail',
          query: { ticketId: item.TicketId, taskId: item.TaskId, key: this.searchStr },
        });
      }
      // location.href = url.href;
    },
    // 触底加载
    async onLoad() {
      if (this.refreshing) {
        this.lists = [];
        this.refreshing = false;
      }
      await this.getTicketByType(this.sidebarNum);
      this.finished = true;
    },
    async onRefresh() {
      // 清空列表数据
      this.finished = false;
      await this.onLoad();
    },
  },
};
</script>
<style lang="less" scoped>
.show-search {
  position: fixed;
  z-index: 999;
  top: 50px;
  right: 0;
  width: 75vw;
  &.is-stretch {
    width: 100vw;
  }
}
.show-search-list {
  margin-top: 50px;
}
.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}
.icon-left {
  width: 2em;
}
.navbar {
  left: 0;
  transition: left 0.2s ease-out;
  &.is-fold {
    left: -25vw;
    transition: left 0.2s ease-out;
  }
}
.todo-box {
  .nav {
    position: fixed;
    left: 0;
    transition: left 0.2s ease-out;
    &.is-fold {
      left: -25vw;
      transition: left 0.2s ease-out;
    }
    overflow: scroll;
    background-color: #f7f8fa;
    &::-webkit-scrollbar {
      display: none;
    }
    height: 100%;
    .sidebar {
      width: 25vw;
    }
  }
  .content {
    overflow: hidden;
    height: 100%;
    .todo-title {
      position: fixed;
      width: 75vw;
      top: 0;
      height: 50px;
      z-index: 999;
      background: #fff;
      transition: all 0.2s ease-out;
      background-color: #f7f8fa;
      &.is-stretch {
        width: 100vw;
        transition: width 0.2s ease-out;
      }
    }
    .todo-list {
      margin-top: 50px;
      width: 75vw;
      transition: width 0.2s ease-out;
    }
    display: flex;
    .list-box {
      margin-left: 25vw;
      display: flex;
      flex-direction: column;
      flex: 1 0 auto;
      height: 100%;
      overflow: scroll;
      transition: margin-left 0.2s ease-out;
      &.is-stretch {
        margin-left: 0;
        transition: margin-left 0.2s ease-out;
        .todo-list {
          width: 100vw;
          transition: width 0.2s ease-out;
        }
      }
      .van-pull-refresh {
        flex: 1 0 auto;
      }
      ::v-deep .van-cell {
        color: #000;
      }
      .details-box {
        .top {
          display: flex;
          justify-content: space-evenly;
        }
        .foot {
          .foot-item {
            display: flex;
            justify-content: flex-end;
            .van-button {
              height: 30px;
            }
          }
        }
      }
    }
  }
}
</style>
